<template>
  <van-pull-refresh
    v-model="isLoading"
    pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..."
    success-text="Refresh Berhasil"
    @refresh="onRefresh"
  >
    <div class="authentication">
      <BaseHeaderBar
        title="Verifikasi Data"
        :showCustomerService="false"
        :showBackButton="true"
      />
      <div class="content" @dblclick.prevent>
        <div class="authList">
          <div class="auth_box" @click="goIdentity">
            <img src="@/assets/img/auth1.png" alt="" />
            <div>Informasi data identitas</div>
            <van-icon class="arrow" name="arrow" size="12" />
            <span
              :style="{
                color: authInfo.iddrentityInfoStatus ? '#155C2D' : '#bbb',
              }"
              >{{
                authInfo.iddrentityInfoStatus
                  ? "Sudah Verifikasi"
                  : "Belum Verifikasi"
              }}</span
            >
          </div>
          <div class="auth_box" @click="goBasicInfo">
            <img src="@/assets/img/auth2.png" alt="" />
            <div>Informasi Dasar</div>
            <van-icon class="arrow" name="arrow" size="12" />
            <span
              :style="{
                color: authInfo.indrformationStatus ? '#155C2D' : '#bbb',
              }"
              >{{
                authInfo.indrformationStatus
                  ? "Sudah Verifikasi"
                  : "Belum Verifikasi"
              }}</span
            >
          </div>
          <div class="auth_box" @click="goBankInfo">
            <img src="@/assets/img/auth3.png" alt="" />
            <div>Informasi Bank</div>
            <van-icon class="arrow" name="arrow" size="12" />
            <span
              :style="{
                color: authInfo.badrnkStatus ? '#155C2D' : '#bbb',
              }"
              >{{
                authInfo.badrnkStatus ? "Sudah Verifikasi" : "Belum Verifikasi"
              }}</span
            >
          </div>
          <div class="auth_box" @click="goFaceRecognition">
            <img src="@/assets/img/auth4.png" alt="" />
            <div>Koleksi wajah</div>
            <van-icon class="arrow" name="arrow" size="12" />
            <span
              :style="{
                color: authInfo.podrrtraitStatus ? '#155C2D' : '#bbb',
              }"
              >{{
                authInfo.podrrtraitStatus
                  ? "Sudah Verifikasi"
                  : "Belum Verifikasi"
              }}</span
            >
          </div>
        </div>
        <div class="checkbox">
          <img
            class="check-icon"
            @click="checked = !checked"
            :src="
              require(`@/assets/img/checked-${checked ? '' : 'no-'}icon.png`)
            "
          />
          <span class="privacy">
            Mengkonfirmasi dan Menyetujui《
            <a @click.stop="goAgreement">Perjanjian Pinjaman</a>
            》
          </span>
        </div>
        <button type="button" class="confirm-btn" @click="onSubmit">
          Kirim
        </button>
      </div>
      <van-dialog
        v-model="visible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-tip" src="@/assets/img/location-tip.jpg" />
        <div class="location-confirm" @click="onLocationConfirm">
          <img src="@/assets/img/location-confirm.jpg" />
        </div>
      </van-dialog>
      <van-dialog
        v-model="denyVisible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-deny" src="@/assets/img/location-deny.jpg" />
        <div class="location-confirm" @click="onCloseDialog('denyVisible')">
          <img src="@/assets/img/location-confirm.jpg" />
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Toast, PullRefresh, Dialog } from 'vant'
// import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'
Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Authentication',
  mixins: [dialogVisible],
  components: {
    // VueSlickCarousel,
    [Dialog.Component.name]: Dialog.Component
  },

  data () {
    return {
      isLoading: false,
      authInfo: {},
      checked: true,
      denyVisible: false,
      currentIndex: 0
    }
  },
  created () {
    this.getAuthInfo()
  },
  methods: {
    getAuthInfo (isRefresh) {
      request.post('audrthIdentityPage')
        .then(res => {
          console.log('信息认证', res.audrthList, JSON.stringify(res.audrthList))
          this.authInfo = res.audrthList
          isRefresh && (this.isLoading = false)
        })
    },
    beforeChange (oldSlideIndex, newSlideIndex) {
      this.currentIndex = newSlideIndex
    },
    goIdentity () {
      if (this.authInfo.iddrentityInfoStatus) {
        Toast('Anda sudah verifikasi data identitas diri')
        return
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/identity',
        query: {
          amount
        }
      })
    },
    goBasicInfo () {
      if (this.authInfo.indrformationStatus) {
        Toast('Anda sudah verifikasi data informasi dasar')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/basicInfo',
        query: {
          amount
        }
      })
    },
    goBankInfo () {
      if (this.authInfo.badrnkStatus) {
        Toast('Anda sudah verifikasi data bank')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/bankInfo',
        query: {
          type: 'auth',
          amount
        }
      })
    },
    goFaceRecognition () {
      if (this.authInfo.podrrtraitStatus) {
        Toast('Anda telah menyelesaikan verifikasi pengumpulan wajah')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      this.$router.push({
        path: '/faceRecognition'
      })
    },
    goAgreement () {
      const { amount } = this.$route.query
      this.$router.push({
        path: '/agreement',
        query: {
          amount
        }
      })
    },
    validate () {
      if (!this.checked) {
        Toast('Mengkonfirmasi dan Menyetujui "Perjanjian Pinjaman"')
        return false
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      if (!this.authInfo.podrrtraitStatus) {
        Toast('Silakan verifikasi koleksi wajah terlebih dahulu')
        return false
      }
      return true
    },
    onLocationConfirm () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log('position', position)
          this.reportLocation(position.coords).then(() => {
            this.submitOrder()
          })
        }, (err) => {
          console.log(err)
          switch (err.code) {
            case err.PERMISSION_DENIED:
              this.denyVisible = true
              this.hasDeny = true
              break
          }
        })
      }
      this.onCloseDialog()
    },
    onSubmit () {
      if (!this.validate()) return
      this.isReportLocation().then(res => {
        if (res.whetherGrab === 1) {
          if (this.hasDeny) {
            this.denyVisible = true
          } else {
            this.visible = true
          }
        } else {
          this.submitOrder()
        }
      })
    },
    submitOrder () {
      if (this.flag) return
      this.flag = true
      const { amount } = this.$route.query
      const postData = {
        apdrplicationAmount: amount,
        prdromotionChannel: 'AppStore',
        portraitAdd: 1
      }
      // 获取设备信息
      getMetadata()
        .then(res => {
          // 提交设备信息
          return request.post('deviceInformation', res)
        })
        .then(() => {
          // 提交订单
          return request.post('sudrbmitloanOrder', postData)
        })
        .then(() => {
          // 跳转到首页
          this.$router.go(-1)
        })
        .catch(error => {
          // 错误处理
          console.error(error)
        })
        .finally(() => {
          // 结束操作
          this.flag = false
        })
    },
    isReportLocation () {
      return request.post('posisyonnanEstime')
    },
    onRefresh () {
      this.getAuthInfo(true)
    },
    reportLocation (data = {}) {
      console.log(typeof data, data, 'location')
      const { longitude = 0, latitude = 0, position = '', address = '', country = '', provinces = '', city = '', county = '', street = '' } = data
      const postData = {
        usdrerLongitude: longitude,
        usdrerLatitude: latitude,
        usdrerPositioning: position,
        usdrerAddress: address,
        usdrerCountries: country,
        usdrerProvinces: provinces,
        usdrerCity: city,
        usdrerCounty: county,
        usdrerStreet: street,
        crdrawl_source: 1
      }
      return request.post('grdrabLocation', postData)
    }
  }
}
</script>

<style scoped lang="less">
.authentication {
  background: #f8f8f8;
  min-height: 100vh;
}
.content {
  .authList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* 列之间的间隔 */
    padding: 0 20px; /* 容器两侧的内边距 */
    margin-top: 30px;

    .auth_box {
      position: relative;
      display: flex;
      align-items: center;
      height: 122px;
      width: 305px;
      background: #ffffff;
      border-radius: 27px;
      padding: 0 20px;
      padding-bottom: 30px;
      img {
        width: 71px;
        margin-right: 10px;
      }
      div {
        width: 220px;
        font-weight: 400;
        font-size: 30px;
        color: #333333;
      }
      .arrow {
        margin-left: auto;
      }
      span {
        position: absolute;
        bottom: 20px;
        left: 102px;
        font-weight: 600;
        font-size: 24px;
        color: #999;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  margin-top: 80px;
  padding: 0 20px;
  a {
    border-bottom: 1px solid #333;
  }
  .check-icon {
    width: 30px;
    margin-right: 10px;
  }
}

.confirm-btn {
  .submit-btn;
  width: 700px;
  position: fixed;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.location-tip {
  width: 100%;
  margin: 30px auto 0;
}

.location-confirm {
  width: 578px;
  height: 93px;
  margin: 40px auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.location-deny {
  width: 100%;
  margin: 30px auto 0;
}
.van-dialog {
  background: #fff;
}
</style>
