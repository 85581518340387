var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-pull-refresh',{attrs:{"pulling-text":"Tarik ke bawah untuk refresh...","loosing-text":"Lepaskan untuk refresh...","success-text":"Refresh Berhasil"},on:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('span')]},proxy:true}]),model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',{staticClass:"authentication"},[_c('BaseHeaderBar',{attrs:{"title":"Verifikasi Data","showCustomerService":false,"showBackButton":true}}),_c('div',{staticClass:"content",on:{"dblclick":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"authList"},[_c('div',{staticClass:"auth_box",on:{"click":_vm.goIdentity}},[_c('img',{attrs:{"src":require("@/assets/img/auth1.png"),"alt":""}}),_c('div',[_vm._v("Informasi data identitas")]),_c('van-icon',{staticClass:"arrow",attrs:{"name":"arrow","size":"12"}}),_c('span',{style:({
              color: _vm.authInfo.iddrentityInfoStatus ? '#155C2D' : '#bbb',
            })},[_vm._v(_vm._s(_vm.authInfo.iddrentityInfoStatus ? "Sudah Verifikasi" : "Belum Verifikasi"))])],1),_c('div',{staticClass:"auth_box",on:{"click":_vm.goBasicInfo}},[_c('img',{attrs:{"src":require("@/assets/img/auth2.png"),"alt":""}}),_c('div',[_vm._v("Informasi Dasar")]),_c('van-icon',{staticClass:"arrow",attrs:{"name":"arrow","size":"12"}}),_c('span',{style:({
              color: _vm.authInfo.indrformationStatus ? '#155C2D' : '#bbb',
            })},[_vm._v(_vm._s(_vm.authInfo.indrformationStatus ? "Sudah Verifikasi" : "Belum Verifikasi"))])],1),_c('div',{staticClass:"auth_box",on:{"click":_vm.goBankInfo}},[_c('img',{attrs:{"src":require("@/assets/img/auth3.png"),"alt":""}}),_c('div',[_vm._v("Informasi Bank")]),_c('van-icon',{staticClass:"arrow",attrs:{"name":"arrow","size":"12"}}),_c('span',{style:({
              color: _vm.authInfo.badrnkStatus ? '#155C2D' : '#bbb',
            })},[_vm._v(_vm._s(_vm.authInfo.badrnkStatus ? "Sudah Verifikasi" : "Belum Verifikasi"))])],1),_c('div',{staticClass:"auth_box",on:{"click":_vm.goFaceRecognition}},[_c('img',{attrs:{"src":require("@/assets/img/auth4.png"),"alt":""}}),_c('div',[_vm._v("Koleksi wajah")]),_c('van-icon',{staticClass:"arrow",attrs:{"name":"arrow","size":"12"}}),_c('span',{style:({
              color: _vm.authInfo.podrrtraitStatus ? '#155C2D' : '#bbb',
            })},[_vm._v(_vm._s(_vm.authInfo.podrrtraitStatus ? "Sudah Verifikasi" : "Belum Verifikasi"))])],1)]),_c('div',{staticClass:"checkbox"},[_c('img',{staticClass:"check-icon",attrs:{"src":require(`@/assets/img/checked-${_vm.checked ? '' : 'no-'}icon.png`)},on:{"click":function($event){_vm.checked = !_vm.checked}}}),_c('span',{staticClass:"privacy"},[_vm._v(" Mengkonfirmasi dan Menyetujui《 "),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.goAgreement.apply(null, arguments)}}},[_vm._v("Perjanjian Pinjaman")]),_vm._v(" 》 ")])]),_c('button',{staticClass:"confirm-btn",attrs:{"type":"button"},on:{"click":_vm.onSubmit}},[_vm._v(" Kirim ")])]),_c('van-dialog',{attrs:{"showConfirmButton":false,"close-on-click-overlay":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('img',{staticClass:"location-tip",attrs:{"src":require("@/assets/img/location-tip.jpg")}}),_c('div',{staticClass:"location-confirm",on:{"click":_vm.onLocationConfirm}},[_c('img',{attrs:{"src":require("@/assets/img/location-confirm.jpg")}})])]),_c('van-dialog',{attrs:{"showConfirmButton":false,"close-on-click-overlay":""},model:{value:(_vm.denyVisible),callback:function ($$v) {_vm.denyVisible=$$v},expression:"denyVisible"}},[_c('img',{staticClass:"location-deny",attrs:{"src":require("@/assets/img/location-deny.jpg")}}),_c('div',{staticClass:"location-confirm",on:{"click":function($event){return _vm.onCloseDialog('denyVisible')}}},[_c('img',{attrs:{"src":require("@/assets/img/location-confirm.jpg")}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }